<template>
    <div id="Join">
        <section id="join-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">お問い合わせ</h1>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-1.jpg'),
                        require('@/assets/background/background-1-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section id="join-title" class="title-section-style hide-mb">
            <h2 class="title f29">お問い合わせ</h2>
        </section>
        <section id="join-form" class="w60">
            <JoinForm></JoinForm>
        </section>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import { addBackgroundImage, wrap, scrollToElement } from '@/utils/common.js'
const JoinForm = defineAsyncComponent(() => import('@/components/JoinForm.vue'))
export default {
    name: 'Join',
    components: { JoinForm },
    setup() {
        return {
            addBackgroundImage,
            wrap,
            scrollToElement,
        }
    },
}
</script>
<style lang="scss" scoped></style>
